<template>
  <v-container class="align-stretch layout-container">
    <BaseHeader
      :title="$t('topics.title')"
      :style-variant="11"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ $t('topics.headerDescription') }}</p>
      </template>
    </BaseHeader>

    <TopicList />
  </v-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader'
import HeaderDesign from './HeaderDesign'
import TopicList from './TopicList'

export default {
  components: {
    BaseHeader,
    HeaderDesign,
    TopicList
  }
}
</script>
