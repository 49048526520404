/**
 * Mixin to track the currently performing action.
 * It's useful to prevent multiple actions from being performed at the same time.
 * If used properly, it can be used to show a spinner or disable a button while an action is being performed.
 */
export default {
  data: () => ({
    performingAction: null
  }),

  methods: {
    /**
     * @param {string} action – provide the unique name of the action
     * @param {boolean} notMe – if true, it will check if any action is being performed except the provided action ("myself")
     */
    isPerformingAction (action, notMe = false) {
      return notMe
        ? this.performingAction && this.performingAction !== action
        : this.performingAction === action
    },

    /**
     * Indicate that an action is being performed.
     * @param {string} action – provide the unique name of the action
     */
    startAction (action) {
      this.performingAction = action
    },

    /**
     * Indicate that the action has been completed.
     */
    endAction () {
      this.performingAction = null
    },

    /**
     * Higher order function to wrap a function that performs an action.
     * This is helpful to ensure the state is handled properly.
     *
     * @param {string} action – choose a unique name for the action
     * @param {Function} fn – the function that will run during the action
     * @returns {Promise<*>}
     */
    async withPerformingAction (action, fn) {
      this.startAction(action)
      try {
        return await fn()
      } finally {
        this.endAction()
      }
    }
  }
}
