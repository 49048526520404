<template>
  <BlurredBackdrop
    :density="1"
    :is-blurred="isBlurred"
    :margin-top="marginTop"
    :min-height="minHeight"
  >
    <template v-slot:info>
      <div>
        {{ $t('alerts.upselling.upgrade.0') }}
        <!-- Use wrapper span to track the event as @click is not called on <router-link> -->
        <span @click="$tracking.event('BlurredBackdrop', 'Clicked', 'Upgrade')">
          <router-link
            class="pointer white--text font-weight-bold"
            :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
          >
            {{ $t('alerts.upselling.upgrade.link') }}
          </router-link>
        </span>
        {{ $t('alerts.upselling.upgrade.1') }}
      </div>
    </template>
    <slot />
  </BlurredBackdrop>
</template>

<script>
import BlurredBackdrop from './BlurredBackdrop.vue'

export default {
  components: { BlurredBackdrop },
  props: {
    isBlurred: {
      type: Boolean,
      default: true
    },
    marginTop: {
      type: String,
      default: '0'
    },
    minHeight: {
      type: String,
      default: '150px'
    }
  }
}
</script>
