<template>
  <v-scroll-y-transition leave-absolute>
    <TopicCard
      v-bind="$attrs"
      v-on="$listeners"
    />
  </v-scroll-y-transition>
</template>

<script>
/**
 * Wrapper component for TopicCard with a slide transition.
 * We can't put the transition directly on the TopicCard component because
 * animations doesn't work anymore as expected.
 */
import TopicCard from './TopicCard'

export default {
  components: {
    TopicCard
  }
}
</script>
