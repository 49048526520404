<template>
  <v-alert
    type="error"
    class="mb-0"
    outlined
    text
  >
    <v-row align="center">
      <v-col class="grow">
        <div class="error-text">
          {{ $t('topics.list.errors.generic.0', { error }) }}<br>
          {{ $t('topics.list.errors.generic.1') }}
        </div>
      </v-col>
      <v-col class="shrink">
        <v-btn
          icon
          class="error--text"
          @click="dismiss"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      required: true
    }
  },

  methods: {
    dismiss () {
      this.$emit('dismiss')
    }
  }
}
</script>
